body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.log-holder {
  margin: 65px auto;
  padding: 30px;
  max-width: 80%;

}

.phone-holder{
  margin: 2em auto;
}

.short-description-holder{
  
  margin: 10px auto;
  width: 90%;
  padding: 10px;
  font-size: 1rem;
  text-align: justify;

  li {
    text-align: left;
  }
}

.nip-holder {
  margin: 20px auto;
  width: 50%;
  text-align: center;

  font-size: 1rem;

}
